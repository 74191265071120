.contact-div {
  width: 90%;
  margin-left: 5%;
  margin-top: 12%;
  margin-bottom: 10%;
}

.row {
  width: 100%;
  display: flex;
  justify-content: center;
}

.world-map {
  width: 70%;
  display: flex;
  margin: 0 auto 4rem;
}

.underline-contact {
  margin-bottom: 3em;
}

.contact-p {
  text-align: center;
  font-size: 1.5em;
}

.fa-5x {
  margin-bottom: 10px;
}

.fa-github:hover {
  color: #9cdaf1;
}

.fa-linkedin:hover {
  color: #0e76a8;
}

.fa-twitter:hover {
  color: #00acee;
}

.fa-medium-m:hover {
  color: rgb(106, 105, 105);
}

.fa-envelope-square:hover {
  color: #d44638;
}

.fa-chrome:hover {
  color: #4C8BF5;
}
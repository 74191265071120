.navbar {
  background-color: #cec5ff;
  color: black;

  @media (max-width: 768px) {
    display: none;
  }
}

a:hover {
  color: #8800ff;
  text-decoration: underline;
}

.nav-link {
  color: black;
  font-size: 1.3em;
  font-weight: 500;
}

a {
  color: black;
  font-size: 1.3em;
}

.nav-item {
  margin-left: 1.6em;
}

.navbar-toggler-icon {
  color: rgb(48, 46, 46);
  font-size: 1.4em;
}

.title {
  height: 100vh;
  width: 100vh;
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  letter-spacing: 1px;
  font-size: 1vw;
}

h1 {
  text-transform: uppercase;
  text-align: center;
  margin: 10px 0;
  letter-spacing: 3px;
  color: white;
  line-height: 0.8em;
}

h4 {
  font-family: "Raleway", sans-serif;
  text-align: center;
  color: white;

}

@media only screen and (min-width: 200px) {
  h1 {
    font-size: 18vw;
    margin-top: 5%;
    font-weight: 500;
    letter-spacing: 0;
  }

  h4 {
    font-size: 2.1em;
  }
}

@media only screen and (min-width: 600px) {
  h1 {
    font-size: 15vw;
    margin-top: 10%;
    font-weight: 500;
  }

  h4 {
    font-size: 2.3em;
  }
}

@media only screen and (min-width: 768px) {
  h1 {
    font-size: 8em;
    margin-top: 10%;
    font-weight: 500;
    letter-spacing: 0;
  }

  h4 {
    font-size: 2.7em;
  }
}

@media only screen and (min-width: 992px) {
  h1 {
    font-size: 9em;
    font-weight: 500;
    margin-top: 5%;
  }

  h4 {
    font-size: 3.5em;
  }
}

@media only screen and (min-width: 1200px) {
  h1 {
    font-size: 7.4em;
    margin-top: 0.3em;
    font-weight: 300;
  }

  h4 {
    font-size: 3em;
    font-weight: 400;
  }
}

.main-box {
  background-image: url("https://images.unsplash.com/photo-1415025148099-17fe74102b28?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2040&q=80");
  background-size: cover;
  height: 24vh;
  width: 100vw;

  @media (max-width: 768px) {
    height: 18vh;
  }
}

.title {
  width: 100%;
}

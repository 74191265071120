.portfolio-div {
  width: 90%;
  margin-left: 5%;
  margin-top: 12%;
}

.underline {
  height: 3px;
  width: 9%;
  background: linear-gradient(135deg, #0b54a1 0%, #efa7af 100%);
  margin-bottom: 4em;
}

.portfolio-card {
  padding-bottom: 2em;
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 150px) and (max-width: 1199px) {
  .big-screen {
    background: url("../ipad3.png");
    /* width: 100vw;
    height: 100vw; */
    width: 1200px;
    height: 872px;
    margin-top: 4em;
    background-repeat: no-repeat;
    background-size: stretch;
  }

  .computer-carousel {
    display: none;
  }

  .ipad-carousel-item {
    display: block;
    height: 80%;
    width: 25%;
    margin-left: 1.9%;
    margin-top: 3.7%;
  }

  .iphone-carousel {
    display: none;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .big-screen {
    background: url("../screen.jpg");
    width: 1200px;
    height: 872px;
    margin-top: 4em;
    margin-left: 4em;
    background-repeat: no-repeat;
    background-size: fit;
  }

  .ipad-carousel {
    display: none;
  }

  .iphone-carousel {
    display: none;
  }

  .computer-carousel-item {
    display: block;
    width: 76.5%;
    margin-left: 3.5%;
    margin-top: 3%;
  }
}

/* Portfolio cards */

.portfolio-gallery {
  margin-top: 50px;
  width: 100%;
  margin: 50px auto;
  max-width: 100%;
  box-sizing: border-box;
}

.portfolio-card {
  position: relative;
  display: block;
  width: 100%;
  height: 250px;
  margin: 0 auto 2rem;
  background-size: cover;
  border-radius: 10px;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, .5);
  overflow: hidden;
  transition: all .4s ease;
}

@media screen and (min-width: 37.5em) {
  .portfolio-card {
    height: 65vh;
  }
}

@media screen and (min-width: 55em) {
  .portfolio-card {
    display: inline-block;
    margin-left: 25px;
  }
}

.portfolio-card__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 10;
}

.portfolio-card__overlay:after {
  content: '';
  width: 100%;
  border-radius: 10px;
  height: 100%;
  background-color: #202020;
  opacity: 0; /* Default state with no opacity */
  position: absolute;
  top: 0;
  z-index: -10;
  left: 0;
  transition: all .3s ease;
}

.portfolio-card__title {
  position: absolute;
  width: 100%;
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Adjust to exact center */
  font-size: 2rem;
  font-weight: 500;
  color: white;
  text-align: center;
  letter-spacing: 5px;
  opacity: 0; /* Initially hidden */
  transition: all .3s ease;
}

.portfolio-card__link {
  position: absolute;
  width: 40%;
  top: 60%; /* Position slightly below the title */
  left: 30%; /* Center horizontally */
  padding: 8px;
  margin: 0 auto;
  font-weight: 400;
  color: white;
  text-align: center;
  border: 1px solid white;
  opacity: 0; /* Initially hidden */
  transition: opacity .3s ease, top .3s ease;
}

a:hover {
  text-decoration: none;
}

.portfolio-card__link:hover {
  text-decoration: none;
  background-color: white;
  color: #202020;
}


.portfolio-card:hover .portfolio-card__overlay:after {
  opacity: 0.75; /* Visible on hover */
}

.portfolio-card:hover .portfolio-card__title,
.portfolio-card:hover .portfolio-card__link {
  top: 40%; /* Adjust both to center vertically on hover */
  opacity: 1; /* Make visible on hover */
}

.portfolio-card:hover .portfolio-card__link {
  top: 50%; /* Initial top position for all links */
}

.portfolio-card:hover .portfolio-card__link:nth-of-type(2) {
  top: 65%; /* Adjust the second link's position */
}

.card1 {
  background-image: url('https://i.imgur.com/ifUChnn.png');
}

.sequin {
  background-image: url('https://i.imgur.com/VFUYB0z.png');
}

.tpc {
  background-image: url('https://i.imgur.com/GN4sDAK.png');
}

.unit-switch {
  background-image: url('https://i.imgur.com/gLA0mxF.png');
}

.sample-size {
  background-image: url('../sample-size.png');
}

.card4 {
  background-image: url('https://i.imgur.com/F75dN6c.png');
}

.card5 {
  background-image: url('https://i.imgur.com/a7prOSj.png');
}

.card6 {
  background-image: url('https://i.imgur.com/Jrg8YVm.png');

}


/* End portfolio cards */
.main-photo {
  width: 20rem;
  border-radius: 4rem;
  margin: 0 auto;
}

.about-div {
  width: 90%;
  margin-left: 5%;
  margin-top: 2%;
}

.name-highlight {
  position: relative;
  font-weight: bold;
}

.name-highlight::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, #efa7af, #0b54a1);
  border-radius: 2px;
}

.about-content {
  display: flex;
  flex-direction: row;
}

@media (max-width: 948px) {
  .about-content {
    flex-direction: column;
  }
}

.openai-logo {
  width: 4em;
  height: auto;
  margin-bottom: 0.5em;
}


.underline {
  height: 3px;
  width: 9%;
  background: linear-gradient(135deg, #efa7af 0%, #0b54a1 100%);
}

.about-text {
  margin-top: 3em;
  width: 90%;
  font-size: 1.2em;
  text-align: center;
  margin-left: 5%;
}

.about-text a {
  color: #21158f;
  text-decoration: underline;
  font-size: unset;
}

.btn-outline-danger {
  border-radius: 0;
  margin-left: 25%;
  width: 50%;
  margin-top: 2em;
  font-size: 1.4em;
  color: #0b54a1;
  border-color: #0b54a1;
}

.btn-outline-danger:hover {
  background-color: #0b54a1;
  border-color: #0b54a1;
}

@media only screen and (max-width: 420px) {
  .btn-outline-danger {
    width: 50%;
    margin-left: 25%;
  }

  .main-photo {
    width: 60%;
    margin-left: 20%;
  }

  .about-text {
    font-size: 1em;
  }
}

.fa-cloud-download-alt {
  margin-right: 10px;
}

.languages {
  margin-top: 5em;
}

.fa-4x {
  margin-top: 1em;
  margin-bottom: 10px;
}

.javascript:hover {
  color: #f0db4f;
}

.react:hover {
  color: #61dbfb;
}

.ruby:hover {
  color: #820c02;
}

.html:hover {
  color: #e44d26;
}

.css:hover {
  color: #264de4;
}

.sql:hover {
  color: #00758f;
}

.vue:hover {
  color: #41b883;
}

.git:hover {
  color: #f1502f;
}

.sass:hover {
  color: #c69;
}

.bootstrap:hover {
  color: #602c50;
}

.node:hover {
  color: #68a063;
}

.python:hover {
  color: #4b8bbe;
}

.resume-section {
  text-align: center;
  margin: 2rem 0;
}

.resume-text {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #333;
}

.resume-download-btn {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: 500;
  color: #0b54a1;
  background: transparent;
  border: 2px solid #0b54a1;
  border-radius: 12px;
  text-decoration: none;
  transition: all 0.3s ease;
}

.resume-download-btn:hover {
  background: #0b54a1;
  color: #fff;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.blog-div {
  width: 80%;
  margin-left: 10%;
  margin-top: 12%;
}

.blog-card-title {
  font-weight: 700;
}

.blog-card-img {
  height: 10em;
}

.blog-card-text {
  margin-bottom: 2.5em;
}

.blog-card-group {
  margin-top: 3em;
}
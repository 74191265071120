* {
  font-family: "Raleway", sans-serif;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

h2 {
  font-size: 2.6em;
}
